
























































	import isImage from './helpers/isImage';
	import Vue from 'vue';

	export default Vue.extend({
		name: "ImagePreview",
		props: ['file', 'error', 'enableCrop', 'onlyImagesAllowed', 'cropRequired'],
		computed: {
			imgSrc: function() : string {
				return this.$store.state.imgPreviews[this.file.id];
			},
			croppingAvailable: function() : boolean {
				if (!this.enableCrop) {
					return false;
				}

				/**
				* Disable for Internet Explorer due to vue-advanced-cropper not supporting IE.
				*/
				return !/MSIE|Trident/.test(window.navigator.userAgent);
			},
			previewSize: function (): { width: string, height: string } | undefined {
				if (!this.onlyImagesAllowed) {
					return;
				}

				return {
					width: this.file?.previewWidth + 'px',
					height: this.file?.previewHeight + 'px',
				};
			},
			showPreview: function() : boolean {
				if (!this.imgSrc) {
					return false;
				}

				if (this.file.rehydrated) {
					return true;
				}

				if (this.cropRequired && !this.file.cropped) {
					return false;
				}

				return this.file.processed;
			}
		},
		methods: {
			isImage: function() : boolean {
				return isImage(this.file);
			},
			editFile: function (file: MOxieFile) : void {
				this.$store.dispatch('setCurrentFile', { file });
				this.$store.commit('OPEN_EDITOR');
			},
			storePreviewDimensions: function(file: MOxieFile) : void {
				const { clientWidth, clientHeight } = this.$refs.image as Element;

				// Skip saving dimensions if either is 0.
				// This prevents issue where multi-page forms change the dimensions to 0.
				if (!clientWidth || !clientHeight) {
					return;
				}

				this.$store.state.storage.storePreviewDimensions(file.id, {
					width: clientWidth,
					height: clientHeight,
				});
			}
		}
	});
