












































































	import GPFUPCropper from './GPFUPCropper.vue';
	import File from "./File.vue";
	import ErredFile from "./ErredFile.vue";
	import Droparea from "./Droparea.vue";
	import {mapState} from 'vuex';
	import Vue from "vue";
	import draggable from 'vuedraggable';
	import PortalVue from "portal-vue";

	const $ = window.jQuery;

	Vue.use(PortalVue);

	const setDragCursor = (value: boolean) => {
		const html = document.getElementsByTagName('html').item(0);
		html?.classList.toggle('gpfup--dragging', value)
	}

	export default Vue.extend({
		name: "GPFUP",
		props: [
			'enableCrop',
			'enableSorting',
			'formId',
			'fieldId',
			'up',
			'strings',
			'cropRequired',
			'aspectRatio',
			'maxWidth',
			'maxHeight',
			'minWidth',
			'minHeight',
			'exactWidth',
			'exactHeight',
		],
		data: function() {
			return {
				drag: false,
			};
		},
		components: {
			File,
			ErredFile,
			Droparea,
			GPFUPCropper,
			draggable,
		},
		computed: {
			portalName: function() : string {
				return `gpfup-cropper-${this.formId}-${this.fieldId}`;
			},
			portalMountTo: function() : string {
				return `#gpfup-cropper-portal-${this.formId}-${this.fieldId}`;
			},
			...mapState({
				files: (state: any) => state.files,
				erredFiles: (state: any) => state.erredFiles,
				currentFile: (state: any) => state.editor.currentFile,
				editorOpen: (state: any) => state.editor.open,
			}),
			allFiles: {
				get() {
					return this.$store.getters.allFiles;
				},
				set(value: MOxieFile[]) {
					this.$store.commit('SET_FILE_ORDER', value.map((file) => file.id));
				}
			},
			maxFiles: function() {
				const maxFiles = parseInt(this.up.settings.gf_vars.max_files, 10);

				return maxFiles > 0 ? maxFiles : Infinity;
			},
			reachedMaxFiles: function() {
				// @ts-ignore
				return this.files.length >= this.maxFiles;
			},
			onlyImagesAllowed: function() {
				const extensions = this.up?.settings?.filters?.mime_types[0]?.extensions.split(',');

				/**
				 * Filter image extensions that will be used to determine if the uploader is only accepting images.
				 *
				 * @since 1.0-alpha-3.0
				 *
				 * @param string[] 	extensions  Allowed image extensions
				 */
				const imageExtensionWhitelist = window.gform.applyFilters(
					'gpfup_image_extension_whitelist',
					['jpg', 'jpeg', 'png', 'gif']
				);

				let onlyImages = true;

				if (extensions && extensions.length) {
					for ( let extension of extensions ) {
						extension = extension.trim();

						if (extension && imageExtensionWhitelist.indexOf(extension) === -1) {
							onlyImages = false;
							break;
						}
					}
				}

				return onlyImages;
			},
			containerClasses: function() {
				const classes: { [className: string]: unknown } = {
					'gpfup--images-only': this.onlyImagesAllowed,
					'gpfup--has-error': this.erredFiles.length,
					'gpfup--maxed': this.reachedMaxFiles,
					'gpfup--sortable': this.enableSorting,
					'gpfup--has-files': this.files.length
				};

				/* Stub in cropper options to extract stencilComponent if present */
				const cropperOptions = window.gform.applyFilters('gpfup_cropper_options', {});

				if (cropperOptions?.stencilComponent) {
					classes[`gpfup--${cropperOptions?.stencilComponent}`] = true;
				}

				return classes;
			},
			dragOptions() {
				return {
					animation: 200,
					handle: '.gpfup__drag-handle',
					group: `gpfup-files-${this.formId}-${this.fieldId}`,
					disabled: false,
					ghostClass: "ghost",
				};
			}
		},
		methods: {
			browse: function () : void {
				$(`#field_${this.formId}_${this.fieldId} div.moxie-shim input[type=file]`).trigger('click');
			},
			drop: function (event: DragEvent) : void {
				this.up.addFile(Array.from(event.dataTransfer!.files));
			},
			onDragStart() {
				setDragCursor(true);
			},
			onDragEnd() {
				setDragCursor(false);
			},
		},
	});
