








	export default {
		name: "LoadingBar",
		props: ['file'],
		data: function() {
			return {
				/**
				* metMinimumDisplayTime is used for showing the progress bar for a minimum amount of time. This provides
				* a more consistent experience between files and helps the visitor know that the file was uploaded
				* successfully.
				*/
				metMinimumDisplayTime: false,
				metMinimumDisplayTimeTimeout: undefined,
			}
		},
		watch: {
			percentage: function (newPercent, oldPercent) {
				if (newPercent === 100) {
					this.metMinimumDisplayTimeTimeout = setTimeout(() => {
						this.metMinimumDisplayTime = true;
					}, 750);
				}
			}
		},
		beforeDestroy() {
			if (this.metMinimumDisplayTimeTimeout) {
				clearTimeout(this.metMinimumDisplayTimeTimeout)
			}
		},
		computed: {
			percentage: function() {
				// Stay at 99% if the percentage is 100 and the status is NOT 5 (DONE)
				if (this.file.percent === 100 && this.file.status !== 5) {
					return 99;
				}

				return this.file.percent;
			}
		}
	}
